<template>
  <div class="gap5">
    <div class="leftWrap">
      <div class="pagenamPT">
        <h3 class="pagename2">입금</h3>
      </div>
      <div class="moneyread">
        <h3>{{$t('front.cash.moneyCheckList')}}</h3>
        <ul>
          <li>{{$t('front.cash.moneyCheckListA')}}</li>
          <li>{{$t('front.cash.moneyCheckListB')}}</li>
          <li>{{$t('front.cash.moneyCheckListC')}}</li>
          <li>{{$t('front.cash.moneyCheckListD')}}</li>
        </ul>
      </div>

      <div class="moneyinfoappli">
        <div class="moneyinfo">
          <h3 class="applih">{{$t('front.cash.moneyCheckListE')}}</h3>
          <p class="applip">{{$t('front.cash.moneyCheckListF')}}</p>
          <a class="applibtn" @click="getAcc">{{$t('front.cash.moneyCheckListG')}}</a>
        </div>
        <div class="moneyinfo">
          <ul class="applistep">
            <li><em>1</em>{{$t('front.cash.we')}} <strong>{{$t('front.cash.moneyCheckListG')}}</strong>{{$t('front.cash.moneyCheckListH')}}</li>
            <li><em>2</em>{{$t('front.cash.moneyCheckListI')}}</li>
            <li><em>3</em><strong>{{$t('front.cash.moneyCheckListJ')}}</strong>{{$t('front.cash.moneyCheckListK')}}<strong>{{$t('front.cash.moneyCheckListL')}}</strong>{{$t('front.cash.moneyCheckListM')}}</li>
          </ul>
        </div>
        <div class="appliinputwrap">
          <ul class="appliinput">
            <li>{{$t('front.cash.moneyHave')}}</li>
            <li><span class="numb">{{thousand(userData.cashAmt)}}</span> {{$t('front.common.money')}}</li>
          </ul>
          <ul class="appliinput">
            <li>{{$t('front.cash.moneyInput')}}</li>
            <li><input type="text" pattern="[0-9.,]+" class="numb" :placeholder="$t('front.cash.inputNumber')" v-model="setCurrentChargeMoney" :readonly="true" @focus="onFocus">{{$t('front.common.money')}}</li>
          </ul>
          <ul class="moneybtnwrap ml120">
            <li class="one"><a @click="setMoney(10000)">1{{$t('front.cash.manwon')}}</a></li>
            <li class="one"><a @click="setMoney(30000)">3{{$t('front.cash.manwon')}}</a></li>
            <li class="one"><a @click="setMoney(50000)">5{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(100000)">10{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(300000)">30{{$t('front.cash.manwon')}}</a></li>
            <li class="two"><a @click="setMoney(500000)">50{{$t('front.cash.manwon')}}</a></li>
            <li class="thr"><a @click="setMoney(1000000)">100{{$t('front.cash.manwon')}}</a></li>
            <li class="four"><a @click="setMoney(0)">{{$t('front.cash.correct')}}</a></li>
          </ul>
          <div class="applibtns">
            <a @click="onSubmit('pc')">{{$t('front.cash.moneyCheckListL')}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="rightWrap">
      <div class="strTablescr">
        <div class="strTablePC">
          <table class="strTablePT">
            <colgroup>
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
              <col width="20%">
            </colgroup>
            <thead>
              <tr>
                <th>{{$t('front.board.applyDay')}}</th>
                <th>{{$t('front.board.processMoney')}}</th>
                <th>{{$t('front.board.processBonus')}}</th>
                <th>{{$t('front.board.processDay')}}</th>
                <th>{{$t('front.board.processState')}}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="cashList.length > 0">
                <template v-for="item in cashList" :key="item.cashIdx">
                  <tr>
                    <td>{{dateFormat(item.regDate)}}</td>
                    <td>{{thousand(item.cashAmt)}}</td>
                    <td>0</td>
                    <td>{{dateFormat(item.updDate)}}</td>
                    <td>{{computedCashStatus(item.cashStatus).text}}</td>
                  </tr>
                </template>
              </template>
              <template v-else>
                <tr>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="strTableM">
            <div class="strTablePTM">
              <template v-if="cashList.length > 0">
                <template v-for="item in cashList" :key="item.cashIdx">
                  <ul>
                    <li><em>{{$t('front.board.applyDay')}}</em>{{dateFormat(item.regDate)}}</li>
                    <li><em>{{$t('front.board.processMoney')}}</em>{{thousand(item.cashAmt)}}</li>
                    <li><em>{{$t('front.board.processBonus')}}</em>0</li>
                    <li><em>{{$t('front.board.processDay')}}</em>{{dateFormat(item.updDate)}}</li>
                    <li><em>{{$t('front.board.processState')}}</em>{{computedCashStatus(item.cashStatus).text}}</li>
                  </ul>
                </template>
              </template>
              <template v-else>
                <ul>
                    <li class="nodata">{{$t('front.common.notFoundList')}}</li>
                </ul>
              </template>
            </div>
        </div>
      </div>

      <pagination :pageNum="pageInfo.page"
                  :pageSize="pageInfo.count_per_list"
                  :totalCount="pageInfo.tatal_list_count"
                  @goToPage="onCashListSearch" />
    </div>
    </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
import { getDateStr, getSubDaysDate, thousand } from '@/libs/utils'
import { cashDelete, cashIn } from '@/api/cash'

import store from '@/store'
export default {
  name: 'Charge',
  components: {
    Pagination
  },
  watch: {
    type: function () {
      // if (this.type === 'list') {
      this.onCashListSearch()
      // }
    },
    setCurrentChargeMoneyStr () {
      // this.setCurrentChargeMoneyStr = thousand(this.setCurrentChargeMoneyStr)
    },
    setCurrentChargeMoney () {
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
      const parts = this.setCurrentChargeMoney.split('.')
      const v = parts[0].replace(/\D/g, '')
      const dec = parts[1]
      const calcNum = Number((dec !== undefined ? v + '.' + dec : v))
      // use this for numeric calculations
      console.log('number for calculations: ', calcNum)
      let n = new Intl.NumberFormat('en-EN').format(v)
      n = dec !== undefined ? n + '.' + dec : n
      this.setCurrentChargeMoney = n
    }
  },
  created () {
    this.onCashListSearch()
  },
  data () {
    return {
      type: 'apply',
      setCurrentChargeMoney: '',
      isApplyBonus: false,
      bonus: 0,
      cashList: [],
      allChecked: false,
      cashType: 'in',
      searchDate: {
        startDate: getDateStr(getSubDaysDate(new Date(), 2), 'yyyy-MM-dd 00:00:00'),
        endDate: getDateStr(new Date(), 'yyyy-MM-dd 23:59:59')
      },
      isProcessing: false
    }
  },
  methods: {
    async getAcc () {
      const title = '계좌문의입니다.'
      const content = '계좌문의입니다.'
      const params = {
        title: title,
        content: content,
        boardType: 'faq',
        category: '',
        faqType: 'bankaccount'
      }

      await this.setSaveAccountBoard(params, async function () {
        await this.loadList()
      }.bind(this))
    },
    async onRemoveMsg () {
      let checkedCount = 0
      this.cashList.forEach(item => {
        if (item.checked) {
          checkedCount++
        }
      })
      if (checkedCount <= 0) {
        this.onCheck('front.cash.noSelectMessage')
        return false
      }
      const confirm = await this.onConfirm('front.cash.confirmDeleteMessage')

      if (confirm) {
        for (let i = 0, iLen = this.cashList.length; i < iLen; i++) {
          const item = this.cashList[i]
          if (item.checked) {
            cashDelete(item).then(response => {
            })
          }
        }
        this.onCheck('front.cash.completeDeleteMessage')
        this.onCashListSearch()
      }
    },
    setMoney (value) {
      let money = Number(this.setCurrentChargeMoney.replace(/,/g, ''))
      if (value) {
        money += value
      } else {
        money = value
      }
      this.setCurrentChargeMoney = thousand(money)
      // this.setCurrentChargeMoney = thousand(this.setCurrentChargeMoney)
    },
    async onSubmit (device = 'mobile') {
      if (this.setCurrentChargeMoney && this.setCurrentChargeMoney !== '0') {
        const confirm = await this.onConfirm('front.cash.confirmCharge')

        if (confirm) {
          if (!this.isProcessing) {
            this.isProcessing = true
            this.emitter.emit('Loading', true)

            const params = {
              memId: this.userData.memId,
              cashType: 1, // in,
              cashStatus: 'in',
              cashAmt: this.setCurrentChargeMoney.replace(/,/g, '')
            }

            cashIn(params).then(response => {
              store.dispatch('storeUserData').then(userData => {

              }).then(async () => {
                console.log(response)
                const result = response.data

                if (result.resultCode === '0') {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('front.cash.completeCharge')
                  if (confirm) {
                    if (device === 'mobile') {
                      this.replacePageByName('my')
                    } else {
                      location.reload()
                    }
                  }
                } else {
                  this.emitter.emit('Loading', false)
                  const confirm = await this.onCheck('api.' + result.resultCode)
                  if (confirm) {
                    if (device === 'mobile') {
                      this.replacePageByName('my')
                    } else {
                      location.reload()
                    }
                  }
                }
              })
            }).catch(err => {
              this.emitter.emit('Loading', false)
              console.error(err)
            })
          }
        }
      } else {
        this.onAlert('warningart', 'front.cash.emptyPrice')
      }
    }
  }
}
</script>
<style scoped>

</style>

<style src="@/styles/striNew.css"></style>
